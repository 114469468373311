<template>
  <div class="container about-header">
    <app-header title="About BATTERS" side="left" />
  </div>
  <div class="container">
    <div class="row page-row">
      <div class="col-12 col-xl-7 text-col">
        <p>
          With the ultrafast development of the battery sector, a need for a
          centralised tool for actors to find up-to-date information was
          identified to support the development of the
          <span class="text-bold text-primary-light">circular economy</span> of
          batteries. By acknowledging that each actor individually holds some
          information while also missing part of it, this website helps experts
          take action in batteries' circularity through
          <span class="text-bold text-primary-light">coordinated efforts</span>.
        </p>
        <p>
          <span class="text-bold">BATTERS</span> was designed to be an
          intuitive,
          <span class="text-bold text-primary-light">collaborative</span>
          platform where all actors in the value chain can easily share or find
          the
          <span class="text-bold text-primary-light">latest information</span>
          on any topics relevant to the industry (technologies of batteries,
          regulation, new manufacturing and recycling plants, etc.) in their
          research scope.
        </p>
        <p class="text-bold fs-20 mt-2 mb-4">
          Share the latest news on the battery sector with the community!
        </p>
        <router-link :to="{ name: 'News' }">
          <button class="btn-filled">Submit news</button>
        </router-link>
      </div>
      <div class="col-12 col-xl-5 img-col mt-4">
        <app-image src="batters-about.png" alt="Batters Lifecycle" />
      </div>
    </div>
  </div>
  <div class="container about-header mt-4">
    <app-header title="Origins of the project" side="center" />
  </div>
  <div class="container mb-4">
    <div class="row">
      <div class="col-12 col-xl-5 img-col">
        <app-image
          src="batters-origins.jpg"
          alt="Logo WeLOOP & Hauts-de-France Region"
        />
      </div>
      <div class="col-12 col-xl-7 text-col mt-4">
        <p>
          <span class="text-bold">BATTERS</span> is the expansion of the
          same-titled project, carried out by the French environmental and LCA
          consulting company
          <a
            class="inline-link-light text-bold"
            href="https://weloop.org/en/"
            alt="WeLOOP website"
            target="_blank"
            >WeLOOP</a
          >
          for the Hauts-de-France Region. The project aimed to provide the
          Region with a roadmap to implement a circular economy in the battery
          sector, as France's most strategic region for battery development.
        </p>
        <p>
          To know more about this initiative and WeLOOP's activities in the
          battery sector:
        </p>
        <a href="https://weloop.org/en/" alt="WeLOOP website" target="_blank">
          <button class="btn-filled">Click here</button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
};
</script>

<style lang="scss">
@include bp-down(lg) {
  
}
</style>